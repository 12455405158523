<template>
<div class="max-w-screen-xl mx-auto">
  <div class="grid grid-cols-7 main-container items-center">    
    <div class="col-start-3 col-span-3">

      <div class="grid grid-cols-3 items-center">
        <div class="col-start-2 col-span-1">
          <div class="header-line">
            <div>prime</div>
            <div>installs</div>
          </div>      
        </div>
      </div>

      <div class="grid grid-cols-1 items-center">
        <div class="col-span-1 text-center">
          <p class="pl-12">Click the chat button on the lower left to start</p>
        </div>
      </div>

    </div>
  </div>
 </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container {
  height: 85vh;
}

.header-line {
  color: #0d6930;
  font-family: 'Work Sans';
  font-size: 4rem;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 2rem;  
  pointer-events: none;
  text-align: left;
  user-select: none;
}
</style>